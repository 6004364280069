import React, { useState, useEffect } from 'react'
import { Button, Checkbox, Input, Modal } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import notif from '../assets/icon/notif.png'
import Template from '../template'

const options = [
  { id: 1, name: '1 цаг 1000 ₮', hour: 1, price: 1000, profile: '1 Hour' },
  { id: 2, name: '3 цаг 2500 ₮', hour: 3, price: 2500, profile: '3 Hour' },
  { id: 3, name: '7 цаг 5000 ₮', hour: 7, price: 5000, profile: '7 Hour' },
  { id: 4, name: '12 цаг 8000 ₮', hour: 12, price: 8000, profile: '12 Hour' }
]

const Order = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const itemId = Number(id)
  const [emailMain, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [isPhoneValid, setPhoneIsValid] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openModalInfo, setOpenModalInfo] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const filteredItem = options.find(item => item.id === itemId)

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const phoneRegex = /^\d{8}$/

  useEffect(() => {
    localStorage.clear()
  }, [])

  useEffect(() => {
    setOpenModalInfo(true)
  }, [])

  const changeEmail = e => {
    const inputEmail = e.target.value
    setEmail(inputEmail)
    setIsEmailValid(emailRegex.test(inputEmail))
  }

  const changePhone = e => {
    const inputPhone = e.target.value
    setPhone(inputPhone)
    setPhoneIsValid(phoneRegex.test(inputPhone))
  }

  const callQpay = async (price, email) => {
    try {
      const tokenResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/get-token`,
        {}
      )

      const { access_token } = tokenResponse.data
      localStorage.setItem('access_token', access_token)

      const createResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/create`,
        {
          amount: price,
          email: emailMain,
          phone: phone,
          access_token: access_token,
          profile: filteredItem.profile
        }
      )
      const { invoice_id, qPay_shortUrl, qr_image, qr_text, urls } =
        createResponse.data.data

      localStorage.setItem('invoice_id', invoice_id)
      localStorage.setItem('qPay_shortUrl', qPay_shortUrl)
      localStorage.setItem('qr_image', qr_image)
      localStorage.setItem('qr_text', qr_text)
      localStorage.setItem('urls', urls)

      if (createResponse.status === 200) {
        const data = {
          arrayData: urls,
          qrImage: qr_image,
          invoiceId: invoice_id,
          email: emailMain,
          username: createResponse.data.username,
          phone: phone
        }
        navigate(`/user/${itemId}`, { state: data })
      }
    } catch (error) {
      console.error('Error making POST request:', error)
    }
  }

  const handleCancel = () => setOpenModal(false)

  const handleOk = () => {
    setIsChecked(true)
    setOpenModal(false)
  }

  const handleSubmitReal = async () => {
    if (!emailMain || !isEmailValid) {
      setOpenModalInfo(true)
    }
    if (emailMain && isChecked && isPhoneValid) {
      try {
        await callQpay(filteredItem.price)
      } catch (error) {
        console.error('Error in handleSubmit:', error)
      }
    }
  }

  const onChecked = () => setIsChecked(!isChecked)
  const onClicked = () => setOpenModal(true)
  const handleCancelInfo = () => setOpenModalInfo(false)

  return (
    <Template>
      <span className='o-title'>Сонгосон багц</span>
      <div className='o-container'>
        <span className='o-package'>
          {filteredItem?.name || 'Багц олдсонгүй'}
        </span>
        <div className='o-sub-container'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <Input
              className='o-input'
              onChange={changeEmail}
              value={emailMain}
              placeholder='И-мэйл оруулна уу'
            />
            <Input
              style={{ marginTop: 20 }}
              className='o-input'
              onChange={changePhone}
              value={phone}
              placeholder='Утасны дугаар оруулна уу'
            />
            {phone&&!isPhoneValid &&(
              <span
                style={{
                  textAlign: 'left',
                  marginTop: 20,
                  fontSize: 12,
                  color: 'white'
                }}
              >
                Утасны дугаарыг зөв оруулна уу!
              </span>
            )}
          </div>
          <div style={{ marginTop: 10 }}>
            <Checkbox
              checked={isChecked}
              onChange={onChecked}
              style={{
                color: 'white',
                marginTop: 15,
                marginBottom: 30,
                textAlign: 'start'
              }}
            />
            <span
              style={{
                marginLeft: 10,
                textDecoration: 'underline',
                color: 'white',
                fontSize: 12
              }}
              onClick={onClicked}
            >
              Үйлчилгээний гэрээг
            </span>
            <span style={{ color: 'white', fontSize: 12 }}>
              {' '}
              зөвшөөрч байна.
            </span>
          </div>
          <Button className='check-button' onClick={handleSubmitReal}>
            Төлбөр төлөх
          </Button>
        </div>
      </div>

      <Modal
        open={openModalInfo}
        onOk={handleCancelInfo}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={360}
        okButtonProps={{ style: { backgroundColor: 'black' } }}
        closable={false}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <span style={{ fontWeight: 500, fontSize: 20, padding: 40 }}>
            Анхааруулга
          </span>
          <div
            style={{
              height: 70,
              width: 70
            }}
          >
            <img
              src={notif}
              style={{ height: '100%', width: '100%' }}
              alt='ico'
            ></img>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column'
            }}
          >
            <span style={{ textAlign: 'left', marginTop: 20, fontSize: 18 }}>
              Имэйл-г зөв оруулна уу!
            </span>
            <span style={{ marginTop: 20, marginBottom: 20, fontSize: 18 }}>
              Нэвтрэх код болон EBarimt-н мэдээлэллийг илгээхэд уг и-мэйлийг
              ашиглана.
            </span>
          </div>
        </div>
      </Modal>
      <Modal
        className='modal-form'
        open={openModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          style: {
            backgroundColor: 'green',
            color: '#fff'
          }
        }}
        okText='Зөвшөөрөх'
        cancelText='Татгалзах'
        cancelButtonProps={{
          style: {
            backgroundColor: '#f5222d',
            borderColor: '#f5222d',
            color: '#fff'
          }
        }}
      >
        <div className='terms-p'>
          <div className='terms-title'>
            Старлинк Номад ХХК-ний WiFi үйлчилгээний гэрээ
          </div>
          <div>
            <ul>
              Энэхүү WiFi Үйлчилгээний Гэрээ (“Гэрээ”) нь Улаанбаатар төмөр зам
              ХНН-ын суудлын галт тэргэнд Старлинкын сансарын интернэтэд
              суурилсан WiFi интернет үйлчилгээ (“Үйлчилгээ”)-г ашиглах нөхцлийг
              тодорхойлж байна. Энэхүү Үйлчилгээг ашигласнаар та дараах
              нөхцлүүдэд тохирохоо зөвшөөрч байна:
              <li>1. Нөхцөл хүлээн авах</li>
              <li style={{ marginLeft: 10 }}>
                {' '}
                Үйлчилгээг ашигласнаар та энэхүү нөхцөлийг дагаж мөрдөхийг
                зөвшөөрч байгаа болно. Хэрэв та зөвшөөрөхгүй бол даруй
                ашиглахаас татгалзана уу.
              </li>
              <li>2. Үйлчилгээний тайлбар</li>
              <li style={{ marginLeft: 10 }}>
                {' '}
                Энэхүү Үйлчилгээ нь Старлинк хиймэл дагуулын технологийг ашиглан
                интернэт холболтыг хангана. Хиймэл дагуулын холболтын онцлогоос
                шалтгаалан хэрэглэгчид хөдөлгөөн, тааламжгүй цаг агаар, тодорхой
                газар зүйн бүсүүд гэх мэт нөхцөл байдлаас үүдэн үе үе холбоо
                тасалдахыг анхаарна уу.
              </li>
              <li>3. Хэрэглэгчийн эрх, ашиглалт</li>
              <li style={{ marginLeft: 10 }}>
                {' '}
                Үйлчилгээний хувьд бүх зорчигчдод хувь хүний, арилжааны бус
                ашиглалтад нээлттэй байна. Хэрэглэгчид их хэмжээний файлыг татаж
                авах, шууд дамжуулалт хийх, эсвэл хууль бус үйлдэл хийх гэх мэт
                үйлдлүүдийг хориглоно.
              </li>
              <li>4. Холболтын болон Гүйцэтгэлийн хязгаарлалт</li>
              <li style={{ marginLeft: 10 }}>
                {' '}
                Хөдөлгөөнт орчин болон хиймэл дагуулын технологийн онцлогоос
                шалтгаалан холболт нь галт тэрэгний байршил хилийн бүсэд 30 км
                дотор эсвэл галт тэрэг дээгүүрээ халхалах ямар нэг зүйлийн доор
                зогсож эсвэл явж байх, цаг агаарын нөхцөл байдал тааруу буюу
                хүчтэй шороон шуурга, цас бороо орж байх үед, тэнгэр бүрхэг,
                манантай гэх мэт болон сүлжээний хамрах хүрээний бүсээс үүдэн
                тасалдаж болзошгүй.
              </li>
              <li style={{ marginLeft: 10 }}>
                {' '}
                Нэг дор олон хэрэглэгчийн холболтыг тогтвортой байлгах үүднээс
                хэрэглэгч бүр 20mbps -с илүүгүй хурдаар холбогдохоор
                хязгаарласан ба энэ нь энгийн хэрэглээ (2к бичлэг) хийхэд
                саадгүй юм.
              </li>
              <li style={{ marginLeft: 10 }}>
                {' '}
                Холболтыг хугацаагаар тооцох ба тухайн хугацаанд дата урсгалд
                хязгаарлалт байхгүй болно.
              </li>
              <li style={{ marginLeft: 10 }}>
                {' '}
                Бид холболтыг аль болох сайжруулахын төлөө хичээн ажилладаг
                боловч тасралтгүй эсвэл алдаагүй үйлчилгээ үзүүлэхийг
                баталгаажуулахгүй.
              </li>
              <li> 5. Төлбөрийн нөхцөл</li>
              <li style={{ marginLeft: 10 }}>
                {' '}
                Хэрэглэгч өөрийн сонгосон багцыг зөвхөн онлайнаар манай системд
                холбогдсон банкнуудын аппликэшнийг ашиглан шууд хуладлан авалт
                хийх ба худалдан авалт амжилттай болсон тохиолдолд танд сүлжээнд
                нэвтрэх нууц үгийг харуулна (илгээнэ). Уг нууц үгийг 24 цагийн
                дотор ашиглах шаардлатай.
              </li>
              <li style={{ marginLeft: 10 }}>
                {' '}
                Төлбөр төлөх үед оруулсан таны имэйл хаягт нууц болон ибаримтын
                код мөн сүлжээнд нэврэх заавар илгээгдэх болно.
              </li>
              <li> 6. Хэрэглэгчийн зан төлөв</li>
              <li style={{ marginLeft: 10 }}>
                {' '}
                Хэрэглэгчид хууль бус үйл ажиллагаа явуулах, хүчирхийлэлтэй
                сэдэв агуулсан контент дамжуулахыг хориглоно. Үүнд хууль бус,
                гүтгэх шинжтэй үйлдэл болон бусдын эрхэд халдсан үйлдлүүд
                багтана.
              </li>
              <li> 7. Нууцлал ба Мэдээллийн ашиглалт</li>
              <li style={{ marginLeft: 10 }}>
                {' '}
                Бид үйлчилгээг сайжруулах болон энэхүү Гэрээнд нийцүүлэх
                зорилгоор зарим мэдээллийг цуглуулдаг. Дэлгэрэнгүй мэдээллийг
                манай Нууцлалын бодлогоос [Нууцлалын бодлогын холбоос] үзнэ үү.
              </li>
              <li> 8. Аюулгүй байдал</li>
              <li style={{ marginLeft: 10 }}>
                {' '}
                Үйлчилгээ нь нээлттэй сүлжээний орчинд байдгаас шалтгаалан
                хэрэглэгчид өөрсдийн төхөөрөмж дээр аюулгүй байдлын арга хэмжээг
                хэрэгжүүлэх хариуцлага хүлээнэ. Сүлжээ ашиглах үед хувийн
                мэдээллийг дамжуулахад анхааралтай байхыг зөвлөж байна.
              </li>
              <li> 9. Хариуцлагын хязгаарлалт</li>
              <li style={{ marginLeft: 10 }}>
                {' '}
                WiFi Үйлчилгээг худалдан авсанч доорх нөхцөл байдлуудаас бусад
                үед үйлчилгээг огт авч чадаагүй бол та train@starlinknomad.mn
                хаягаар холбогдож зарим шаардлагатай мэдээллүүдийг бидэнд өгч
                хохиролоо барагдуулах боломжтой.
              </li>
              <li style={{ marginLeft: 10 }}>
                {' '}
                Старлинк Номад ХХК нь доорох давагдашгүй хүчин зүйлүүдээс
                үүдэлтэйгээр ашиглах боломжгүй болсон аливаа хохирол, алдагдалд
                хариуцлага хүлээхгүй болно.
              </li>
              <li style={{ marginLeft: 10 }}>
                {' '}
                Сүлжээнийн хамрах хүрээнээс гарсан, хилийн бүсэд ойртсон, цаг
                агаарын таагүй нөхцөл байдал, галт тэрэгний гэмтэл, сүлжээний
                төхөөрөмжүүдийн гэнэтийн эвдрэл гэмтэл, Старлинкийн сүлжээнний
                доголдол, хэрэглэгчийн төхөөрөмжийн нийцэл зэрэг.
              </li>
              <li> 10. Нэмэлт өөрчлөлт</li>
              <li style={{ marginLeft: 10 }}>
                {' '}
                Бид энэхүү Гэрээг шаардлагатай үед өөрчлөх эрхтэй. Аливаа
                өөрчлөлтүүд нийтлэгдсэнээс хойш үйлчилж, үйлчилгээг үргэлжлүүлэн
                ашигласнаар шинээр оруулсан нөхцлийг хүлээн зөвшөөрсөн болно.
              </li>
              <li> 11. Хандах Эрхийн дуусгавар болгох</li>
              <li style={{ marginLeft: 10 }}>
                {' '}
                Энэхүү Гэрээний нөхцөлийг зөрчсөн, эсвэл зохисгүйд тооцогдох үйл
                ажиллагаанаас шалтгаалан үйлчилгээнд хандах эрхийг
                мэдэгдэлгүйгээр хязгаарлаж эсвэл цуцалж болно.
              </li>
              <li> 12. Холбоо барих мэдээлэл</li>
              <li style={{ marginLeft: 10 }}>
                {' '}
                Энэхүү Гэрээтэй холбоотой асуулт, нэмэлт мэдээлэл авахыг хүсвэл
                train@starlinknomad.mn хаягаар холбогдоно уу.
              </li>
            </ul>
            Энэхүү Үйлчилгээг ашигласнаар та энэхүү Гэрээг уншиж ойлгосон бөгөөд
            энэхүү нөхцөл, болзлыг хүлээн зөвшөөрч байгаагаа баталгаажуулна.
          </div>
        </div>
      </Modal>
    </Template>
  )
}

export default Order
